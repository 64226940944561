<template>
  <div class="layout ranking">
    <div class="header">{{ this.$t("product.list.title_ranking") }}</div>
    <div class="list">
      <ul class="product">
        <li class="no_data" v-if="this.product_data.length <= 0">
          {{ this.$t("product.list.no_data") }}
        </li>
        <li v-for="(value, index) in this.product_data" :key="index">
          <div
            class="image"
            :style="{
              backgroundImage:
                'url(' +
                this.common.get_product_image_url(
                  value.product_id,
                  value.product_images
                ) +
                ')',
            }"
            @click="this.$router.push('/product/detail/' + value.product_id)"
          ></div>
          <div class="desc">
            <div class="seller">{{ value.seller_name }}</div>
            <div class="name">
              <router-link
                :to="{
                  name: 'detail',
                  params: { product_id: value.product_id },
                }"
                >{{ value.product_name }}</router-link
              >
            </div>
            <div class="price">
              <span
                class="percent"
                v-show="value.retail_price - value.sale_price > 0"
                >{{
                  this.common.number_format(
                    ((value.retail_price - value.sale_price) /
                      value.retail_price) *
                      100.0,
                    1
                  )
                }}%</span
              >
              <span class="sale">{{
                this.common.currency_format(value.sale_price, value.unit)
              }}</span>
              <span
                class="avatar"
                v-if="value.item_id != null && value.item_id > 0"
              ></span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <Spinner v-show="processing" />
</template>

<script>
export default {
  name: "Ranking",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.ranking"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.ranking"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      product_data: [],
      search_sorting: {
        column: "sold_count",
        order: "DESC",
      },
      search_page: 1,
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    this.load_product_data();
  },
  methods: {
    load_product_data: function () {
      if (this.data_fully_loaded) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/product/list",
          {
            sorting: self.search_sorting,
            page: self.search_page,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (self.search_page <= 1) {
            self.product_data = [];
          }

          let data = response.data;

          for (let i = 0; i < data.length; i++) {
            self.product_data.push(data[i]);
          }

          if (data.length <= 0) {
            self.data_fully_loaded = true;
          } else {
            self.search_page++;
          }

          // 총 갯수 정보 헤더에서 가져오기
          try {
            let str_info = response.headers.wkt_extra_info;
            let extra_info = JSON.parse(str_info);
            self.product_count = parseInt(extra_info.total_count);
          } catch (e) {
            console.log(e);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //!-- axios
    }, //-- load_product_data
  },
};
</script>

<style scoped>
.layout.ranking {
  display: block;
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;

  padding: 1rem;
}

.layout.ranking > .header {
  font-size: 1.4rem;
  font-weight: 700;
  padding: 1rem 0;
}
</style>
